import { AutoComplete, AutoCompleteChangeEvent, AutoCompleteCloseEvent } from '@progress/kendo-react-dropdowns';
import React, { Component } from 'react';
import { ILookup } from '../../interfaces/ILookup';
import memberService from '../../services/member/Member';

interface IState {
  data: ILookup[]
  value?: string
  loading: boolean
}

interface IProps {
  onSelected: (item: ILookup) => void
  defaultValue: string
  clubId?: number,
  classMode?: boolean
  classId?: number
  classDate?: Date
}

export class MemberLookup extends React.Component<IProps, IState> {

  selected_value?: ILookup;
  timeout: any;  

  constructor(props: IProps) {
    super(props);

    this.state = {
      data: [],
      value: this.props.defaultValue,
      loading: false
    }
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    if(prevProps.defaultValue != this.props.defaultValue){
      this.setState({value: this.props.defaultValue});
    }
  }

  handleOnChange(e: AutoCompleteChangeEvent): void {
        
    let val = e.value as string;

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      
      // Don't refresh the data if an item has been selected
      if(val.endsWith(']') && val.indexOf('[') >= 0) {
      
        this.selected_value = this.state.data.find((v) => {
          return v.name == val;
        });

        if (!this.props.classMode) { val = ''; }

        this.setState({ value: val, loading: false }, () => { 
          if(this.selected_value) { this.props.onSelected(this.selected_value); }
        });
        
      } else {
        
        if(!this.props.classMode) {

          let clubId = this.props.clubId ? this.props.clubId : 0;        
          memberService.basicSearch(val, clubId).then((r) => {
  
            this.setState({ data: r, value: val, loading: false});
          });
        }
        else {

          let classId = this.props.classId ? this.props.classId : 0;        
          let classDate = this.props.classDate ? this.props.classDate : new Date();

          memberService.classSearch(val, classId, classDate).then((r) => {
  
            this.setState({ data: r, value: val, loading: false});
          });
        }
      }
    }, 1000);        

    this.setState({loading: true, value: val});
  }

  handleOnClose(e: AutoCompleteCloseEvent): void {
    if(this.selected_value) { this.props.onSelected(this.selected_value); }
  }

  render(): React.ReactNode {
    return (    
      <AutoComplete 
        placeholder='Member Search'
        data={this.state.data}
        value={this.state.value}
        onChange={(e) => this.handleOnChange(e)}        
        onClose={(e) => this.handleOnClose(e)}
        loading={this.state.loading}
        textField="name"
        style={{width: '100%'}}
      />    
    );
  }    
}
