import { State, toODataString } from '@progress/kendo-data-query';
import authService from '../../api-authorization/AuthorizeService';
import BaseService from '../BaseService';
import IDataResponse from '../../interfaces/IDataResponse';
import IUser from '../../interfaces/IUser';
import IUserClub from '../../interfaces/IUserClub';
import IUserRole from '../../interfaces/IUserRole';
import { IClub, IClubDetail } from '../../interfaces/IClub';
import { ILookup, Lookup } from '../../interfaces/ILookup';
import ILocation from '../../interfaces/ILocation';
import IDiscipline from '../../interfaces/IDiscipline';
import IClassEvent, { IClass } from '../../interfaces/IClassEvent';
import { ClassDiscipline } from '../../interfaces/IClassDiscipline';
import WeeklyPattern, { IWeeklyPattern } from '../../interfaces/IWeeklyPattern';
import IGrade from '../../interfaces/IGrade';
import { User } from 'oidc-client';
import { IMemberClassDetail } from '../../interfaces/IMemberClass';
import { SentItemModal } from '../../screens/messaging/sent-items/SentItemModal';
import IStandardResponse from '../../interfaces/IStandardResponse';
import ISidebarConfig from '../../interfaces/ISidebarConfig';
import { time } from 'console';

export class ClubService extends BaseService  {
      
  constructor() {
    super();

    this.clubTypes = [
      { id: 0, name: 'Not Set', description: '', externalCode: '', value: '' },
      { id: 2, name: 'Standard', description: '', externalCode: '', value: '' },
      { id: 1, name: 'Franchise', description: '', externalCode: '', value: '' }];

    this.clubList = [];

    this.isRedirecting = false;
  }

  clubTypes: Lookup[];

  // Cache of the club list as this is used often.
  clubList: IClub[];

  isRedirecting: boolean;

  async getClubs(){

    if (!authService.isAuthenticated) {
      this.isRedirecting = true;
      authService.signIn().then(() => { this.isRedirecting = false; });
      return [];
    }

    // Attempt to use the cached info to save a round trip
    if (this.clubList.length > 0) {
      return this.clubList;
    }

    const token = await authService.getAccessToken();
    const response = await fetch('/api/club/GetClubList', {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    });

    let data: IClub[] = [];

    try {

      if (!response.ok) { console.log(response); }
      data = await response.json();      

      this.clubList = data as IClub[];

    } catch (e) {

      if (response.status == 401 && !this.isRedirecting) {
        this.isRedirecting = true;
        authService.signIn().then(() => { this.isRedirecting = false; });
      }
    }

    return data;    
  }

  async getPrebookedCapacity (classId: number, classDate : Date) {
    
    if (!classDate) {
      classDate = new Date();  
    }    
    classDate.setMinutes(classDate.getMinutes() - classDate.getTimezoneOffset());
    
    const response = this.getApiCall<IDataResponse<any[]>>(`/api/club/GetPrebookedCapacity/${classId}?classDate=${classDate}`);
    return response;
  }

  async getSidebarConfig () {   
    const response = this.getApiCall<ISidebarConfig>(`/api/club/GetSidebarConfig`);
    return response;
  }

  async getClassMembers (classId: number, dataState: State, selected_date: Date, is_grading : boolean = false) {
    var filterData = {
      classDate: selected_date,
      includeEnquiries: false,
      classId: classId
    }

    const response = await this.postODataApiCall<IDataResponse<IMemberClassDetail[]>>(`api/club/GetClassMembers/${classId}`, filterData, dataState);
        
    for (var i = 0; i < response.data.length; i++) {

      let item = response.data[i];

      let tmpDate: Date | undefined = item.dateAdded;
      if (tmpDate) item.dateAdded = new Date(tmpDate);
      
      tmpDate = item.lastAttendance;
      if (tmpDate) {item.lastAttendance = new Date(tmpDate)}

      tmpDate = item.lastGrading;
      if (tmpDate) {item.lastGrading = new Date(tmpDate)}

      tmpDate = item.lastGrading;
      if (tmpDate) {item.lastGrading = new Date(tmpDate)}

      tmpDate = item.overPlanDate;
      if (tmpDate) {item.overPlanDate = new Date(tmpDate)}

      let grc = "gc-not-ready";

      switch(item.gradingRequirementFlags) {
        case 1: grc = "gc-needs-attendance"; break;
        case 2: grc = "gc-needs-experience"; break;
        case 3: grc = "gc-ready"; break;
      }
      item.gradingRequirementNameCssClass = grc;

      let flags = "";
      if(!item.allowPhoto) { flags += "<span class='fa-stack fa-lg' title='No photos'><i class='fas fa-camera fa-stack-lg'></i><i class='fas fa-ban fa-stack-lg fg-red'></i></span>" }
      
      if(item.requireTerms) {
        if(item.hasTerms){
          flags += "<i class='fas fa-file-signature fg-green fa-lg' title='Terms accepted' style='margin-right: 10px'></i>";
        } else {
          flags += "<span class='fa-stack fa-lg' style='margin-right: 10px' title='Terms required'><i class='fas fa-file-signature fa-stack-lg'></i><i class='fas fa-ban fa-stack-lg fg-red'></i></span>";
        }
      }
      
      if(item.outstandingFees) {
        flags += `<span title='${item.debtInfo}'><i class='fas fa-coins fg-red fa-lg' style='margin-right: 10px'></i></span>`;
      }

      if(item.overPlan){        
        flags += `<span title='${item.overPlanDate?.toLocaleDateString()}'><i style='margin-right: 10px' class='fas fa-hand-paper fa-lg fg-red' style='margin-right:10px'></i></span>`;
      }
      
      item.flags = flags;

      item.firstNameCssClass = "name-wrapper ";
      item.lastNameCssClass = "name-wrapper ";

      if (!is_grading) {
        if (item.lastAttendance == null && item.memberStatusId == this.member_status_prospect) {
          item.firstNameCssClass += " first-timer-and-prospect";
          item.lastNameCssClass += " first-timer-and-prospect";
        } else if (item.memberStatusId == this.member_status_prospect) {
          item.firstNameCssClass += " prospect-row";
          item.lastNameCssClass += " prospect-row";
        } else if (item.lastAttendance == null) {
          item.firstNameCssClass += " first-timer";
          item.lastNameCssClass += " first-timer";
        }
      }
    }

    return response;
  }

  async getClass(classId: number) {
    
    const response = this.getApiCall<IClass>(`/api/club/GetClass/${classId}`);
    return response;
  }
  

  async getClub(clubId: number) {

    let state: State = {};
    let users: IUser[];

    try {
      let response = this.getUsers(clubId, state).then(u => {
        users = u.data;
        return this.getApiCall<IClubDetail>(`api/club/GetClub/${clubId}`);
      }).then(c => {
  
        c.dateOpened = new Date(c.dateOpened);
        if (typeof c.ownerId === "number") { c.ownerId = this.getUserByPerson(c.ownerId, users); }
        if (typeof c.adminId === "number") { c.adminId = this.getUserByPerson(c.adminId, users); }
        if (typeof c.instructorId === "number") { c.instructorId = this.getUserByPerson(c.instructorId, users); }
        if (typeof c.clubTypeId === "number") { c.clubTypeId = this.getClubType(c.clubTypeId); }
  
        if (c.address) {
          if (!c.address.gridEast) { c.address.gridEast = 0; }
          if (!c.address.gridNorth) { c.address.gridNorth = 0; }
          if (!c.address.longitude) { c.address.longitude = 0; }
          if (!c.address.latitude) { c.address.latitude = 0; }
        }
  
        return c;
      });
  
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  getClubType(clubTypeId: number): ILookup {

    let matchedLookup: ILookup = new Lookup();// { id: 0, name: '' };

    this.clubTypes.some((u, i, a) => {
      if (u.id === clubTypeId) { matchedLookup = u; return true; }
    });

    return matchedLookup;
  }

  async getClubLocations(clubId: number, dataState: State) {
    
    const response = await this.getODataApiCall<IDataResponse<ILocation[]>>(`api/club/GetRoomsAndVenues/${clubId}`, dataState);
    return response;
  }

  async getClassesAndEvents(clubId: number, dataState: State, include_expired: boolean) {
    var filterData = {
      IncludeExpired: include_expired,
    };

    const response = await this.postODataApiCall<IDataResponse<IClass[]>>(`api/club/GetClassesAndEvents/${clubId}`,filterData, dataState);
    for (var i = 0; i < response.data.length; i++) {

      var tmpDate = response.data[i].startDate;
      if (tmpDate != null) response.data[i].startDate = new Date(tmpDate);

      tmpDate = response.data[i].endDate;
      if (tmpDate != null) response.data[i].endDate = new Date(tmpDate);
    }
    return response;
  }

  async getClasses(clubIds: number[], classDate: Date)
  {
    const body = {
      clubIds: clubIds,
      classDate: classDate.toISOString()
    };

    const response = await this.postApiCall<IClass[]>(`/api/club/GetClassList/`, body);
    return response;
  }

  async getClassDisciplines(classId: number, clubId: number, dataState: State) {
    const response = await this.getODataApiCall<IDataResponse<ClassDiscipline[]>>(`/api/club/GetClassDisciplines/${classId}?clubId=${clubId}`, dataState);
    return response;
  }

  async getDisciplines(clubId: number, dataState: State) {
    const response = await this.getODataApiCall<IDataResponse<IDiscipline[]>>(`api/club/GetDisciplines/${clubId}`, dataState);
    for (var i = 0; i < response.data.length; i++) {

      var tmpDate = response.data[i].effectiveFrom;
      if (tmpDate != null) response.data[i].effectiveFrom = new Date(tmpDate);

      tmpDate = response.data[i].effectiveTo;
      if (tmpDate != null) response.data[i].effectiveTo = new Date(tmpDate);
    }

    return response;
  }

  async getDisciplineClasses(disciplineId: number, clubId: number, trialStartDate?: Date): Promise<IClass[]> {

    const response = await this.postApiCall<IClass[]>(`api/club/getDisciplineClasses/${clubId}`, {      
      clubId: clubId,
      disciplineId: disciplineId,
      classDate: trialStartDate      
    });

    return response;
  }

  async getDisciplineGrades(discipline_id: number, dataState: State): Promise<IDataResponse<IGrade[]>> {

    const response = await this.getODataApiCall<IDataResponse<IGrade[]>>(`api/club/GetDisciplineGrades/${discipline_id}`, dataState);
    return response;
  }

  async getUsers(clubId: number, dataState: State) {
            
    const response = await this.getODataApiCall<IDataResponse<IUser[]>>(`api/club/GetUsers/${clubId}`, dataState);
    return response;
  }

  getUserByPerson(personId: number, users: IUser[]): IUser {

    let matchedUser: IUser = new IUser();

    users.some((u, i, a) => {
      if (u.personId === personId) { matchedUser = u; return true; }
    });

    return matchedUser;
  }

  async getParentClubList () {
    const response = await this.getApiCall<IDataResponse<IGrade[]>>(`api/club/getParentClubList`);
    return response;
  }


  async getUserClubs(clubId: number, userId: string, dataState: State) {

    let body = { UserId: userId };
    const response = await this.postODataApiCall<IDataResponse<IUserClub[]>>(`api/club/GetUserClubs/${clubId}`, body, dataState);

    for (var i = 0; i < response.data.length; i++) {

      var tmpDate = response.data[i].userSince;
      if (tmpDate != null) response.data[i].userSince = new Date(tmpDate);

      tmpDate = response.data[i].userUntil;
      if (tmpDate != null) response.data[i].userUntil = new Date(tmpDate);

      tmpDate = response.data[i].dateAdded;
      if (tmpDate != null) response.data[i].dateAdded = new Date(tmpDate);
    }

    return response;
  }

  async getUserRoles(club_id: number, user_id: string, dataState: any) {

    let body = { UserId: user_id };
    const response = await this.postODataApiCall<IDataResponse<IUserRole[]>>(`api/club/GetUserRoles/${club_id}`, body, dataState);

    return response;
  }

  async getClassAvailabilities (class_id: number, dateFrom: Date, dateTo: Date, dataState : any) {
    
    if (dateFrom != null) this.fixDate(dateFrom);
    if (dateTo != null) this.fixDate(dateTo);

    let body = {
      dateFrom : dateFrom,
      dateTo: dateTo
    }

    const response = await this.postODataApiCall<IDataResponse<any[]>>(`api/club/GetClassAvailabilities/${class_id}`, body, dataState);

    for (var i = 0; i < response.data.length; i++) {

      var tmpDate = response.data[i].dateFrom;
      if (tmpDate != null) response.data[i].dateFrom = new Date(tmpDate);

      tmpDate = response.data[i].dateTo;
      if (tmpDate != null) response.data[i].dateTo = new Date(tmpDate);
    }

    return response;
  }

  async saveClassAvailability (payload: any) {
    this.fixDate(payload.dateFrom);
    this.fixDate(payload.dateTo);

    const response = await this.postApiCall<IDataResponse<any[]>>(`api/club/SaveClassAvailability/${payload.classAvailabilityId}`, payload);

    return response;
  }


  async saveClub(clubInfo: IClubDetail): Promise<Response> {

    let token = await authService.getAccessToken();

    let adminDdl = clubInfo.adminId,
      instructorDdl = clubInfo.instructorId,
      ownerDdl = clubInfo.ownerId,
      clubTypeDdl = clubInfo.clubTypeId


    if (clubInfo.parentClubId && clubInfo.parentClubId != -1) {
      if (typeof(clubInfo.parentClubId) === "object") clubInfo.parentClubId = clubInfo.parentClubId.id;
    } else {
      clubInfo.parentClubId = null;
    }

    if (typeof(clubInfo.adminId) === "object") clubInfo.adminId = clubInfo.adminId.personId;
    if (typeof(clubInfo.instructorId) === "object") clubInfo.instructorId = clubInfo.instructorId.personId;
    if (typeof(clubInfo.ownerId) === "object") clubInfo.ownerId = clubInfo.ownerId.personId;
    if (typeof(clubInfo.clubTypeId) === "object") clubInfo.clubTypeId = clubInfo.clubTypeId.id;    
    
    const response = await fetch(`/api/club/SaveClub/${clubInfo.id}`, {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },      
      method: 'POST',
      body: JSON.stringify(clubInfo)
    });
    
    clubInfo.adminId = adminDdl;
    clubInfo.instructorId = instructorDdl;
    clubInfo.ownerId = ownerDdl;
    clubInfo.clubTypeId = clubTypeDdl;
        
    return response;
  }

  async saveLocation(location: ILocation) {

    let token = await authService.getAccessToken();

    let locationDdl = location.locationTypeId;
    if (typeof (location.locationTypeId) === "object") location.locationTypeId = location.locationTypeId.id;

    const response = await fetch(`/api/club/SaveLocation/${location.locationId}`, {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
      method: 'POST',
      body: JSON.stringify(location)
    });
    
    location.locationTypeId = locationDdl;
    
    return response;
  }

  async deleteClassAvailability (id: number) {

    let token = await authService.getAccessToken();
    
    const response = await fetch(`/api/club/DeleteClassAvailability/${id}`, {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
      method: 'POST'
    });
    
    return response;
  }

  async deleteLocation(id: number) {

    let token = await authService.getAccessToken();
    
    const response = await fetch(`/api/club/DeleteLocation/${id}`, {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
      method: 'POST'
    });
    
    return response;
  }

  async saveClass(classInfo: IClass) {

    let token = await authService.getAccessToken();

    let locationDdl = classInfo.locationId,
      classTypeDdl = classInfo.classTypeId,
      primaryDiscDdl = classInfo.primaryDisciplineId;

    if (typeof (classInfo.locationId) === "object") classInfo.locationId = classInfo.locationId.locationId;
    if (typeof (classInfo.classTypeId) === "object") classInfo.classTypeId = classInfo.classTypeId.id;
    if (typeof (classInfo.primaryDisciplineId) === "object") classInfo.primaryDisciplineId = classInfo.primaryDisciplineId.disciplineId;

    if(classInfo.startDate) classInfo.startDate.setMinutes(classInfo.startDate.getMinutes() - classInfo.startDate.getTimezoneOffset());
    if(classInfo.endDate) classInfo.endDate.setMinutes(classInfo.endDate.getMinutes() - classInfo.endDate.getTimezoneOffset());

    const response = await fetch(`/api/club/SaveClass/${classInfo.classId}`, {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
      method: 'POST',
      body: JSON.stringify(classInfo)
    });

    classInfo.locationId = locationDdl;
    classInfo.classTypeId = classTypeDdl;
    classInfo.primaryDisciplineId = primaryDiscDdl;

    return response;

  }

  async saveClassDisciplines(classId: number, disciplines: ClassDiscipline[])
  {
    let token = await authService.getAccessToken();

    const response = await fetch(`/api/club/SaveClassDisciplines/${classId}`, {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
      method: 'POST',
      body: JSON.stringify(disciplines)
    });
    
    return response;
  }

  async saveClassTimetable(classId: number, timetable: IWeeklyPattern) {

    let token = await authService.getAccessToken();
    
    if(timetable.mondayStart) this.fixDate(timetable.mondayStart);
    if(timetable.mondayFinish) this.fixDate(timetable.mondayFinish); 
    if(timetable.tuesdayStart) this.fixDate(timetable.tuesdayStart); 
    if(timetable.tuesdayFinish) this.fixDate(timetable.tuesdayFinish); 
    if(timetable.wednesdayStart) this.fixDate(timetable.wednesdayStart); 
    if(timetable.wednesdayFinish) this.fixDate(timetable.wednesdayFinish); 
    if(timetable.thursdayStart) this.fixDate(timetable.thursdayStart); 
    if(timetable.thursdayFinish) this.fixDate(timetable.thursdayFinish);
    if(timetable.fridayStart) this.fixDate(timetable.fridayStart);
    if(timetable.fridayFinish) this.fixDate(timetable.fridayFinish);
    if(timetable.saturdayStart) this.fixDate(timetable.saturdayStart);
    if(timetable.saturdayFinish) this.fixDate(timetable.saturdayFinish);
    if(timetable.sundayStart) this.fixDate(timetable.sundayStart);
    if(timetable.sundayFinish) this.fixDate(timetable.sundayFinish);

    const response = await fetch(`/api/club/SaveClassTimetable/${classId}`, {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
      method: 'POST',
      body: JSON.stringify(timetable)
    });

    return response;
  }

  async deleteClass(id: number) {

    let token = await authService.getAccessToken();

    const response = await fetch(`/api/club/DeleteClass/${id}`, {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
      method: 'POST'
    });

    return response;
  }

  async deleteGrade(gradeId: number) {
    let token = await authService.getAccessToken();

    const response = await fetch(`/api/club/DeleteGrade/${gradeId}`, {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
      method: 'POST'
    });

    return response;
  }

  async saveDiscipline(disc_details: IDiscipline) {
    let token = await authService.getAccessToken();

    const response = await fetch(`/api/club/SaveDiscipline/${disc_details.clubId}`, {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
      method: 'POST',
      body: JSON.stringify(disc_details)
    });

    return response;
  }

  async saveGrade(grade: IGrade) {
    let token = await authService.getAccessToken();

    let gradeDdl = grade.categoryId;

    if (typeof (grade.categoryId) === "object") grade.categoryId = grade.categoryId.gradeCategoryId;
    grade.minimumAttendance = Math.round(grade.minimumAttendance);

    const response = await fetch(`/api/club/SaveGrade/${grade.disciplineId}`, {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
      method: 'POST',
      body: JSON.stringify(grade)
    });

    grade.categoryId = gradeDdl;

    return response;
  }

  async deleteDiscipline(disciplineId: number) {
    let token = await authService.getAccessToken();

    const response = await fetch(`/api/club/DeleteDiscipline/${disciplineId}`, {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
      method: 'POST'
    });

    return response;
  }

  // Move a grade by a given number of positions
  async moveGrade(gradeId: number, positions: number) {
    let token = await authService.getAccessToken();

    const response = await fetch(`/api/club/MoveGrade/${gradeId}?positions=${positions}`, {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
      method: 'POST'/*,
      body: `{ "positions": ${positions}}`*/
    });

    return response;
  }

}

const clubService = new ClubService();
export default clubService;